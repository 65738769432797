/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import 'material';
@import 'quill/dist/quill.snow.css';

.preset-option {
    mat-pseudo-checkbox {
        visibility: hidden;
    }
}

.success-snackbar {
    --mdc-snackbar-container-color: rgba(60, 179, 113, 0.90) !important;
}

.info-snackbar {
    --mdc-snackbar-container-color: rgba(65, 105, 225, 0.9) !important;
}

.notif-snackbar {
    --mdc-snackbar-container-color: rgba(30, 41, 59, 0.9) !important;
    --mat-snack-bar-button-color: rgb(249, 205, 11) !important;
}

.warning-snackbar {
    --mdc-snackbar-container-color: rgba(249, 205, 11, 0.9) !important;
}

.error-snackbar {
    --mdc-snackbar-container-color: rgba(205, 92, 92, 0.9) !important;
}
