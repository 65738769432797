/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */

@import 'perfect-scrollbar/css/perfect-scrollbar.css';

/* Json editor */
@import "jsoneditor/dist/jsoneditor.min.css";

json-editor,
json-editor .jsoneditor,
json-editor>div,
json-editor jsoneditor-outer {
    height: 800px;
}

json-editor .jsoneditor {
    @apply border-primary;
}

json-editor .jsoneditor-menu {
    @apply bg-primary;
    @apply border-primary;
}

.jsoneditor-modal .pico-modal-header {
    @apply bg-primary;
}

.jsoneditor-jmespath-label {
    @apply text-primary #{!important};
}
