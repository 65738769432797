// Mat-Tabs: remove padding of tab body content
mat-tab-group {
    .mat-mdc-tab-body-content {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;

        @screen md {
            padding-left: 2rem !important;
            padding-right: 2rem !important;
        }
    }

    &.no-padding {
        .mat-mdc-tab-body-content {
            padding: 0 !important;
        }
    }
}

// MatTabs - Hide mat-tab-header for tabs set in page header
mat-tab-group.page-header-tabs {
    mat-tab-header {
        display: none;
    }
}

//MatTabs - disable scroll for fusescrollbar
mat-tab-group.unscrollable {
    .mat-mdc-tab-body-content {
        overflow: hidden !important;
        display: flex !important;
    }
}

// MatButtonToggle - Custom style for header tabs
mat-button-toggle-group.tabs-button-toggle-group {
    @apply rounded-none #{!important};

    &>mat-button-toggle {
        @apply text-lg #{!important};
        @apply rounded-none #{!important};
        @apply rounded-t-xl #{!important};

        &>span.mat-button-toggle-ripple {
            @apply rounded-none #{!important};
        }
    }

    .mat-button-toggle {
        @apply bg-hover #{!important};

        &.mat-button-toggle-checked {
            &.mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover .mat-button-toggle-focus-overlay {
                opacity: 0;
            }

            @apply bg-default #{!important};

            .mat-button-toggle-label-content {
                @apply text-default #{!important};
            }
        }

        .mat-button-toggle-label-content {
            @apply text-white #{!important};
        }
    }
}

mat-table.sort-bar {
    align-self: flex-start !important;
    background-color: transparent !important;

    mat-header-row {
        @apply border-none;
        @apply gap-2;
        min-height: 0px;
        display: flex;
        flex-wrap: wrap;

        &::after {
            content: none;
        }

        mat-header-cell {
            @apply text-base;
            @apply border-none;
            min-height: 0px;
            border-radius: 9999px;
            height: 40px;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            flex: 0 0 auto !important;

            &:hover {
                @apply bg-hover;
            }

            .mat-sort-header-container {
                @apply w-full;
                @apply justify-center;
            }

            // Active mat-header-cell
            &[aria-sort="ascending"],
            &[aria-sort="descending"] {
                @apply bg-primary #{'!important'};
                @apply border-primary #{'!important'};

                .mat-sort-header-container {

                    .mat-sort-header-content,
                    .mat-sort-header-arrow {
                        @apply text-white;
                    }
                }
            }
        }
    }
}

mat-checkbox {
    input[disabled] {
        opacity: 0;
    }
}

// MatStepper - margin behind matStepHeader
mat-horizontal-stepper.header-margin-bottom {
    .mat-step-header {
        margin-bottom: 1rem;
    }
}

.mat-mdc-tab-body-wrapper {
    height: 100%;
}

.mat-mdc-autocomplete-panel {
    &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar:hover {
        background-color: rgba(0, 0, 0, 0.12);
    }

    &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
        border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb:active {
        box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.54);
        border-radius: 12px;
    }
}

.mat-menu-small {
    &.mat-mdc-menu-panel {
        min-width: 112px !important;

        .mat-mdc-menu-content {
            padding: 0 !important;
        }
    }
}

.align-middle {
    vertical-align: middle !important;
}

.dark {
    .mat-mdc-form-field {
        &.mat-form-field-appearance-fill {
            &.mat-form-field-invalid {
                .mat-mdc-form-field-wrapper {
                    .mat-mdc-form-field-flex {
                        --tw-border-opacity: 1 !important;
                        border-color: rgba(var(--fuse-warn-rgb), var(--tw-border-opacity)) !important;
                    }
                }
            }
        }
    }

    .mat-focused .mat-form-field-required-marker {
        color: var(--fuse-text-default) !important;
    }
}

.theme-rose.light {
    .mat-mdc-unelevated-button.mat-primary {
        --mdc-filled-button-label-text-color: #fff;
        --mdc-filled-button-label-text-color: #fff;
    }

    .mat-mdc-raised-button.mat-primary {
        --mdc-protected-button-label-text-color: #fff;
        --mdc-protected-button-label-text-color: #fff;
    }

    .mat-mdc-mini-fab.mat-primary {
        --mat-fab-small-foreground-color: #fff;
        --mat-fab-small-state-layer-color: #fff;
    }

    .mat-mdc-fab.mat-primary {
        --mat-fab-foreground-color: #fff;
        --mat-fab-state-layer-color: #fff;
    }
}

mat-button-toggle-group {
    mat-button-toggle {
        button {
            mat-pseudo-checkbox.mat-pseudo-checkbox {
                display: none;
            }
        }
    }
}
